<template>
    <div class="section offers" v-if="collections !== null && collections.length>0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <h5 class="post-heading">{{text}} ist in folgenden Merklisten vertreten:</h5>
                </div>

                <!-- <div class="col-12 col-md-6 col-lg-4 col-xl-3" v-for="(item, index) in wishlists" :key="index">
                    <WBAppointment :item="item" :autoWidth="true" :isLtr="isLtr"/>
                </div> -->
                <div class="row mobile--scrolling dropShadowWorkaround">
                    <merkliste-card v-for="(item, index) in collections" :key="index" :item="item" :noslider="true"></merkliste-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { extractMerklisten } from '@/utils/lupe-helpers';
    import { getCollectionsWithContent } from '@/api/collection';

    export default {
        name: 'CollectionBlock',
        components: {
            MerklisteCard: () => import('@/components/cards/Merkliste.vue'),
        },
        props: {
            content: {
                type: Object,
                default: null
            },
        },
        data() {
            return {
                collections: null,
            }
        },
        computed: {
            text(){
                if(this.content!==null){
                    switch(this.content.content_type_name){
                        case "angebot":
                        return "Dieses Angebot";
                        break;
                        case "institution":
                        return "Dieser Ort";
                        break;
                    }
                }
            }
        },
        created() {
          this.getCollections();
      }, 
      methods: {
         getCollections() {
            if(this.content!==null){
                getCollectionsWithContent(this.content.id).then(response => {
                    const { data } = response;
                    this.collections = extractMerklisten(data,5);
                })
            }
        },
    }
}
</script>
<style lang="scss" scoped="">
    @import '@/scss/_variables.scss';
    
    .dropShadowWorkaround {
        padding-bottom: 10px;
    }

</style>
